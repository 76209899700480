import {IObservableArray, toJS, when} from 'mobx';
import {observer, Provider} from 'mobx-react';
import React, {useEffect, useRef} from 'react';

import {discoveryTracker} from '@udemy/browse-event-tracking';
import {
    PAGE_TYPE_CATEGORY,
    PAGE_TYPE_ORG_CATEGORY,
    PAGE_TYPE_ORG_SUBCATEGORY,
    PAGE_TYPE_SUBCATEGORY,
    PAGE_TYPE_SUBS_CATEGORY,
    PAGE_TYPE_SUBS_SUBCATEGORY,
    PAGE_TYPE_TOPIC,
    PAGE_TYPE_SUBS_TOPIC,
    PAGE_TYPE_ORG_TOPIC,
    DiscoveryUnit,
} from '@udemy/discovery-api';
import {TrackingContextProvider} from '@udemy/event-tracking';
import {FunnelLogContextProvider} from '@udemy/funnel-tracking';
import {useI18n} from '@udemy/i18n';
import {
    Aggregation,
    CourseDirectory,
    CourseDirectoryProps,
    useNavigation,
} from '@udemy/react-discovery-units';
import {AlertBanner} from '@udemy/react-messaging-components';
import {Loader} from '@udemy/react-reveal-components';
import {useConsumerSubscriptionPlan} from '@udemy/subscription-graphql';
import {useUDData} from '@udemy/ud-data';

import {DiscoveryListContainerStore} from './discovery-list-container.mobx-store';
import styles from './discovery-list-container.module.less';

type pageTypePropType =
    | typeof PAGE_TYPE_CATEGORY
    | typeof PAGE_TYPE_ORG_CATEGORY
    | typeof PAGE_TYPE_ORG_SUBCATEGORY
    | typeof PAGE_TYPE_SUBCATEGORY
    | typeof PAGE_TYPE_SUBS_CATEGORY
    | typeof PAGE_TYPE_SUBS_SUBCATEGORY
    | typeof PAGE_TYPE_TOPIC
    | typeof PAGE_TYPE_SUBS_TOPIC
    | typeof PAGE_TYPE_ORG_TOPIC;

interface DiscoveryListContainerProps {
    pageObjectId: number;
    pageType?: pageTypePropType;
    pageSize?: number;
    courseCardSize?: 'small' | 'medium' | 'large';
    unit?: DiscoveryUnit;
    presetFilters?: Record<string, unknown>;
    onPageChange?: (componentName?: string) => void;
    filterOrder?: string[];
    courseDirectoryProps?: Partial<CourseDirectoryProps>;
    subsCollectionIds?: string;
    query?: string;
    showAddToCartInCourseCard?: boolean;
    disableMobileAddToCartRedirect?: boolean;
    allowAddToCartSuccessModal?: boolean;
    cartButtonTextGoToCart?: string;
    cartButtonDisableOnGoToCart?: boolean;
    fullQuickView?: boolean;
}

/**
 * TODO: Extract to a separate frontends component, since this is used in other files:
 * https://github.com/udemy/website-django/blob/b54e16689176b2332e1135d112bde90fce7198b0/static/src/udemy/js/collections/collection.react-component.tsx#L7
 * https://github.com/udemy/website-django/blob/b54e16689176b2332e1135d112bde90fce7198b0/static/src/udemy/js/open-badges/certification-discovery-list.react-component.tsx#L6
 */
export const DiscoveryListContainer: React.FC<DiscoveryListContainerProps> = observer(
    ({
        pageObjectId,
        pageType,
        pageSize = 12,
        unit,
        presetFilters,
        onPageChange,
        filterOrder = ['ratings', 'duration'],
        courseDirectoryProps = {},
        subsCollectionIds,
        query,
        showAddToCartInCourseCard = false,
        disableMobileAddToCartRedirect = false,
        allowAddToCartSuccessModal,
        cartButtonTextGoToCart,
        cartButtonDisableOnGoToCart,
        children,
        fullQuickView = false,
    }) => {
        const {gettext} = useI18n();
        const udData = useUDData();
        const [history, location] = useNavigation();
        const consumerSubscriptionPlan = useConsumerSubscriptionPlan();
        const isConsumerSubsSubscriber =
            consumerSubscriptionPlan &&
            'isSubscriber' in consumerSubscriptionPlan &&
            !!consumerSubscriptionPlan?.isSubscriber;

        const store = useRef(
            new DiscoveryListContainerStore(
                {
                    pageObjectId,
                    pageType: pageType ?? '',
                    unit: unit ?? null,
                    history,
                    gettext,
                },
                udData,
            ),
        );

        const fetchData = React.useCallback(async () => {
            // For some reason, on initial render only, this.searchParams is re-evaluated every
            // time it's referenced in this method. Work around this by saving it to a local variable.
            const params = new URLSearchParams(location.search);
            if (isConsumerSubsSubscriber && !params.get('subs_filter_type')) {
                // not using DiscoveryListContainer's presetFilters property to set
                // this filter as this can be further updated by user actions. Preset filter will override any
                // filter property set by user action.
                params.set('subs_filter_type', 'subs_only');
            }
            const extract = (name: string) => {
                const param = params.get(name);
                if (param === null) {
                    return undefined;
                }
                return param === 'true';
            };
            await store.current.fetchUnit({
                p: params.get('p') === null ? undefined : Number(params.get('p') as string),
                pageSize: pageSize,
                selectedTopicIds: params.getAll('course_label'),
                selectedFilters: {
                    subcategory: params.getAll('subcategory'),
                    instructional_level: params.getAll('instructional_level'),
                    lang: params.getAll('lang'),
                    price: params.getAll('price'),
                    duration: params.getAll('duration'),
                    closed_captions: params.getAll('closed_captions'),
                    subs_filter_type: params.getAll('subs_filter_type'),
                    ...presetFilters,
                },
                has_closed_caption: extract('has_closed_caption'),
                has_simple_quiz: extract('has_simple_quiz'),
                has_coding_exercises: extract('has_coding_exercises'),
                has_workspace: extract('has_workspace'),
                has_practice_test: extract('has_practice_test'),
                ratings: params.get('ratings') ?? undefined,
                sortBy: params.get('sort') ?? undefined,
                subs_coll_id: subsCollectionIds,
            });
        }, [isConsumerSubsSubscriber, location.search, pageSize, presetFilters, subsCollectionIds]);

        const refreshPage = () => {
            window.location.reload();
        };

        useEffect(() => {
            const asyncCode = async () => {
                return fetchData();
            };
            asyncCode().then(() => when(() => !store.current.loading));
        }, [fetchData]);

        if (store.current.unit === null && store.current.loading) {
            return <Loader size="medium" block={true} className={styles['loader-spacing']} />;
        }

        if (store.current.error) {
            return (
                <AlertBanner
                    title={gettext('There was a problem loading course recommendations')}
                    body={gettext('Please reload the page to resolve this issue')}
                    ctaText={pgettext('e.g. Refresh a webpage', 'Reload Page')}
                    onAction={refreshPage}
                    udStyle="warning"
                />
            );
        }

        const {
            aggregations,
            items: courses,
            pagination,
            sort_options: sortOptions,
        } = toJS(store.current.unit);
        if (subsCollectionIds) {
            filterOrder.unshift('subs_filter_type');
        }

        const props = {
            aggregations: aggregations as IObservableArray<Aggregation>,
            courses,
            pagination: pagination as {total_page: number; total_item_count: number},
            sortOptions,
            loading: toJS(store.current.loading),
            onPageChange,
            filterOrder,
            showCtaOnPopover: !udData.Config.brand.has_organization,
            query,
            isConsumerSubsSubscriber,
            showAddToCartInCourseCard,
            disableMobileAddToCartRedirect,
            cartButtonTextGoToCart,
            cartButtonDisableOnGoToCart,
            allowAddToCartSuccessModal,
            fullQuickView,
            ...courseDirectoryProps,
        };

        if (!pagination && courses.length === 0) {
            return null;
        }

        return (
            <FunnelLogContextProvider pageType={pageType}>
                <TrackingContextProvider
                    trackingContext={{
                        trackImpressionFunc: discoveryTracker.trackDiscoveryImpression,
                        backendSource: store.current.backendSource,
                    }}
                >
                    <Provider
                        isConsumerSubsSubscriber={isConsumerSubsSubscriber}
                        discoveryListStore={store.current}
                    >
                        <CourseDirectory {...props}>{children}</CourseDirectory>
                    </Provider>
                </TrackingContextProvider>
            </FunnelLogContextProvider>
        );
    },
);
