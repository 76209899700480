import {action, extendObservable} from 'mobx';

import {BrowseCourse} from '@udemy/browse-course';
import {
    DiscoveryItemImpressionEvent,
    attachFrontendTrackingIds,
} from '@udemy/browse-event-tracking';
import {
    DiscoveryAPI,
    DiscoveryAPIOptions,
    DiscoveryUnit,
    DiscoveryUnitItem,
} from '@udemy/discovery-api';
import {
    Aggregation,
    SortOptions,
    switchCollectionType,
    type History,
} from '@udemy/react-discovery-units';
import {UDData} from '@udemy/ud-data';

export interface DiscoveryListContainerStore {
    unit: unit;
    loading: boolean;
    error: Error;
    pageType: string;
    pageObjectId: number;
    history: History;
    gettext: (s: string) => string;
    discoveryAPI: DiscoveryAPI;
    options: fetchUnitOptions;
}

interface DiscoveryListContainerStoreArgument {
    unit: DiscoveryUnit | null;
    pageType: string;
    pageObjectId: number;
    history: History;
    gettext: (s: string) => string;
}

type fetchUnitOptions = DiscoveryAPIOptions & {
    selectedFilters: Record<string, string[]>;
    selectedTopicIds: string[];
    sortBy?: string;
};

type topic = {
    doc_count: number;
    _class: string;
    title: string;
    id: number;
};

type unit = DiscoveryUnit<BrowseCourse> & {
    aggregations: Aggregation[];
    course_labels: topic[];
    sort_options: SortOptions;
};

export class DiscoveryListContainerStore {
    constructor(
        {
            unit = null,
            pageType,
            pageObjectId,
            history,
            gettext,
        }: DiscoveryListContainerStoreArgument,
        globalOverrides: UDData = {} as UDData,
    ) {
        extendObservable(this, {
            unit,
            loading: true,
            error: null,
        });
        if (unit) {
            attachFrontendTrackingIds(this.unit.items);
        }
        this.pageType = pageType;
        this.pageObjectId = Number(pageObjectId);
        this.history = history;
        this.gettext = gettext;
        this.discoveryAPI = new DiscoveryAPI({}, globalOverrides);
    }

    backendSource = DiscoveryItemImpressionEvent.backendSourceOptions.DISCOVERY_ALL_COURSES;

    @action
    async fetchUnit(options: fetchUnitOptions = {} as fetchUnitOptions) {
        this.options = options;
        const {selectedFilters, selectedTopicIds, sortBy, ...passthroughOptions} = options;
        const topicFilter =
            selectedTopicIds && selectedTopicIds.length
                ? {courseLabel: selectedTopicIds.join('|')}
                : {};
        const filterOptions = {
            ...Object.keys(selectedFilters || {}).reduce(
                (prev, key) => ({...prev, [key]: selectedFilters[key].join('|')}),
                {},
            ),
            ...topicFilter,
            sort: sortBy,
        };

        try {
            this.loading = true;
            const unit = await this.discoveryAPI.loadCourses<DiscoveryUnitItem>(this.pageType, {
                pageObjectId: this.pageObjectId,
                ...passthroughOptions,
                ...filterOptions,
            });

            let {aggregations} = unit as unit;
            const {course_labels: topics = [], ...unitWithoutAggregations} = unit as unit;

            if (topics && topics.length) {
                aggregations = [
                    {
                        title: this.gettext('Topic'),
                        allTitle: this.gettext('All Topics'),
                        key: 'course_label',
                        options: topics.map(
                            ({
                                doc_count: count,
                                _class: key = 'course_label',
                                title,
                                id: value,
                            }) => ({
                                count,
                                key,
                                title,
                                value: String(value),
                                selected: selectedTopicIds.includes(String(value)),
                            }),
                        ),
                    },
                    ...aggregations,
                ];
            }

            this.receiveUnit({
                ...unitWithoutAggregations,
                aggregations,
            } as unit);
        } catch (e) {
            this.receiveError(e as Error);
        }
    }

    @action
    receiveUnit(unit: unit) {
        this.loading = false;
        if (
            unit.items.length === 0 &&
            this.options &&
            this.options.selectedFilters &&
            this.options.selectedFilters.subs_filter_type.includes('subs_only')
        ) {
            this.loading = true;
            switchCollectionType(
                'purchasable_only',
                this.history,
                new URLSearchParams(globalThis.location.search),
                true,
            );
        }
        this.unit = unit;
        attachFrontendTrackingIds(this.unit.items);
    }

    @action
    receiveError(error: Error) {
        this.loading = false;
        this.error = error;
    }
}
