import classNames from 'classnames';
import React from 'react';

import {UFBNoticeClickEvent} from '@udemy/browse-event-tracking';
import {Tracker} from '@udemy/event-tracking';
import {useI18n} from '@udemy/i18n';
import {toBusinessUdemy} from '@udemy/organization';
import {MarketplaceOnly} from '@udemy/react-brand-components';
import {Image} from '@udemy/react-core-components';
import {AdvertisingBanner} from '@udemy/react-messaging-components';
import {udLink, useUDData, getNumericSiteStat} from '@udemy/ud-data';

import styles from './top-companies-notice.module.less';

const DEFAULT_LOGOS_OPTIONS = {className: styles.logos, imageHeight: 44};

interface TopCompaniesNoticeProps {
    refParam: string;
    linkParams?: Record<string, unknown>;
    className?: string;
    isOnsiteRequestDemo?: boolean;
    'data-item-index'?: number;
}

/**
 * TODO: Export this component to common components in this app. The Topic page
 * also uses it...
 */
export const TopCompaniesNotice: React.FC<TopCompaniesNoticeProps> = (props) => {
    const {refParam, linkParams = {}, className, isOnsiteRequestDemo = false, ...restProps} = props;
    const {gettext, interpolate} = useI18n();
    const udData = useUDData();
    const {Config, request} = udData;

    const renderLogos = (options = DEFAULT_LOGOS_OPTIONS, udData = {}) => {
        return (
            <div className={options.className}>
                <Image
                    src={udLink.toStorageStaticAsset('partner-logos/v4/nasdaq-dark.svg', udData)}
                    alt="Nasdaq"
                    height={options.imageHeight}
                    width={115}
                />
                <Image
                    src={udLink.toStorageStaticAsset(
                        'partner-logos/v4/volkswagen-dark.svg',
                        udData,
                    )}
                    alt="Volkswagen"
                    height={options.imageHeight}
                    width={44}
                />
                <Image
                    src={udLink.toStorageStaticAsset('partner-logos/v4/netapp-dark.svg', udData)}
                    alt="NetApp"
                    height={options.imageHeight}
                    width={115}
                />
                <Image
                    src={udLink.toStorageStaticAsset(
                        'partner-logos/v4/eventbrite-dark.svg',
                        udData,
                    )}
                    alt="Eventbrite"
                    height={options.imageHeight}
                    width={115}
                />
            </div>
        );
    };

    const handleClick = () => {
        Tracker.publishEvent(
            new UFBNoticeClickEvent({
                locale: request.locale,
                placement: refParam,
            }),
        );
    };

    return (
        <MarketplaceOnly>
            <div>
                <AdvertisingBanner
                    background="light"
                    title={gettext('Top companies trust Udemy')}
                    titleClassName="ud-heading-serif-lg"
                    subtitle={interpolate(
                        gettext("Get your team access to Udemy's top %(num_courses)s+ courses"),
                        {num_courses: getNumericSiteStat(udData, request.locale, 'num_courses')},
                        true,
                    )}
                    submitButtonText={gettext('Try Udemy Business')}
                    submitButtonProps={{
                        udStyle: 'secondary',
                        size: 'medium',
                        componentClass: 'a',
                        href: toBusinessUdemy(
                            'request-demo',
                            {
                                ...linkParams,
                                ref: refParam,
                            },
                            isOnsiteRequestDemo,
                            {Config, request},
                        ),
                        onClick: handleClick,
                        target: isOnsiteRequestDemo ? undefined : '_blank',
                        rel: isOnsiteRequestDemo ? undefined : 'noopener',
                    }}
                    {...restProps}
                    className={classNames(styles['background-container'], className)}
                >
                    {renderLogos(DEFAULT_LOGOS_OPTIONS, {Config})}
                </AdvertisingBanner>
            </div>
        </MarketplaceOnly>
    );
};
