import {
    COURSE_BADGE_INCLUDED_IN_PLANS,
    COURSE_BADGE_PREMIUM,
    COURSE_BADGE_TOP_PICK,
    COURSE_IN_SUBS_PLAN_BADGE_FAMILIES,
    CourseBadgeFamily,
} from '@udemy/browse-course';

export interface SubscriptionCatalogDifferentiatorFeatureType {
    showPremiumBadge: boolean;
    showTopPickBadge: boolean;
    showIncludedInPlansBadge: boolean;
}

// TODO: Clean up after subs catalog diff experiment is over https://udemyjira.atlassian.net/browse/CVR-151
export const getBrowseCourseBadgesContextOptionsByExperiment = (
    experiment?: SubscriptionCatalogDifferentiatorFeatureType,
) => {
    let badgeFamily: CourseBadgeFamily | undefined;
    if (experiment) {
        if (experiment.showTopPickBadge) {
            badgeFamily = COURSE_BADGE_TOP_PICK;
        } else if (experiment.showIncludedInPlansBadge) {
            badgeFamily = COURSE_BADGE_INCLUDED_IN_PLANS;
        } else if (experiment.showPremiumBadge) {
            badgeFamily = COURSE_BADGE_PREMIUM;
        }
    }
    return getBrowseCourseBadgesContextOptions(badgeFamily);
};

// TODO: Clean up after subs catalog diff experiment is over https://udemyjira.atlassian.net/browse/CVR-151
const getBrowseCourseBadgesContextOptions = (experimentVariant?: CourseBadgeFamily) => {
    // Make sure that we don't show the badge that is being tested
    if (!experimentVariant) {
        return {};
    }

    const excludedBadges = COURSE_IN_SUBS_PLAN_BADGE_FAMILIES.filter(
        (badgeFamily) => badgeFamily !== experimentVariant,
    );

    return {
        numberOfBadgesToShow: 2,
        filter: (badge: CourseBadgeFamily) => !excludedBadges.includes(badge),
        priority: {[experimentVariant]: 1},
    };
};
