import classNames from 'classnames';
import React from 'react';

import {useI18n} from '@udemy/i18n';
import {MarketplaceOnly} from '@udemy/react-brand-components';
import {AlertBanner} from '@udemy/react-messaging-components';

interface RefundNoticeProps {
    className: string;
    fullWidth?: boolean;
}

// Note, `ud-full-viewport-container` provided by scaffolding.global.less from `@udemy/styles`
export const RefundNotice: React.FC<RefundNoticeProps> = React.memo(
    ({className = '', fullWidth = false}) => {
        const {gettext} = useI18n();
        return (
            <MarketplaceOnly>
                <div
                    className={classNames(className, {
                        'ud-full-viewport-container': fullWidth,
                    })}
                >
                    <AlertBanner
                        showCta={false}
                        title={gettext('Not sure? All courses have a 30-day money-back guarantee')}
                    />
                </div>
            </MarketplaceOnly>
        );
    },
);
